<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="searchform">
            <el-form-item label="用户名" prop="name">
              <el-input v-model="formInline.name" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="seachFun">查询</el-button>
              <el-button type="primary" @click="resetFun">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-table
        :data="appData"
        stripe
        border
        style="width: 100%"
        :default-sort="{prop: 'date', order: 'descending'}"
      >
        <el-table-column prop="name" label="用户名"></el-table-column>
        <el-table-column prop="mobile" label="手机号"></el-table-column>
        <el-table-column prop="create_at" label="创建时间"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="mini" @click="resetCode(scope.$index, scope.row)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="修改密码" v-model="dialogFormVisible" width="30%" :center="true" top="30vh">
        <el-form :model="resetform">
          <el-form-item label="用户名" :required="true" label-width="80px">
            <el-input v-model="resetform.name" autocomplete="off" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="密码" :required="true" label-width="80px">
            <el-input v-model="resetform.password" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="affirmReset">确 定</el-button>
          </span>
        </template>
      </el-dialog>

      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formInline.page"
      :page-sizes="[10, 20,50]"
      :page-size="formInline.size"
      fixed="right"
      layout="total, sizes, prev, pager, next, jumper"
      :total="listCount">
    </el-pagination>
    </el-card>
  </div>
</template>
<script>
import userapp from "@/model/user";
export default {
  name: "userApp",
  data() {
    return {
      dialogFormVisible: false,
      formInline: {
        name: "",
        page: 1,
        size: 10
      },
      resetform: {
        uid: "",
        name: "",
        password: ""
      },
      listCount: 0,
      appData: []
    };
  },
  async mounted() {
    await this.appuserList();
  },
  methods: {
    async appuserList() {
      const ret = await userapp.app(this.formInline);
      if (ret.code === 200) {
        this.appData = ret.data.list;
        this.listCount = ret.data.count;
      }
    },
    resetCode(index, row) {
      this.dialogFormVisible = true;
      this.resetform.uid = row.uid;
      this.resetform.name = row.name;
      this.resetform.password = '';
    },
    async affirmReset() {
      const ret = await userapp.reset(this.resetform);
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.dialogFormVisible = false;
      } else {
        this.$message.error(ret.message);
      }
    },
    seachFun() {
      this.appuserList();
    },
    resetFun() {
      this.$refs.searchform.resetFields();
      this.appuserList();
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.appuserList();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.appuserList();
    }
  }
};
</script>

<style scoped>
.demo-form-inline {
  margin-top: 20px;
}
.card-header {
  display: flex;
  align-items: center;
}
.el-pagination {
  margin: 30px 0px;
  float: right;
}
</style>
